import axios from "axios";

const STORAGE_KEY = 'tphReferredLandingUrl';
const API_ENDPOINT = '/wp-json/api/tph-sf-phone-numbers/phone-number';
const EXPIRATION_IN_DAYS = 90;
const ENABLED_DIVISIONS = ['Las Vegas','Dallas-Fort Worth','Bay Area'];

export function checkForReferrer() {
    let referrer = fetchReferrer();
    if (window.location.href.toLowerCase().includes("utm_")) {
        persistReferrer(window.location.href);
    } else if (!referrer) {
        persistReferrer(document.referrer);
    }
    
    return referrer;
}

export function getDynamicPhoneNumber(posttype, data) {
    let referrer = checkForReferrer();
    return axios
        .get(API_ENDPOINT, {
            params: {
                division: getDivisionName(posttype, data),
                page_url: window.location.href,
                referrer_url: referrer,
            },
        })
}

export function isEnabledForDivision(posttype, data) {
    return ENABLED_DIVISIONS.includes(getDivisionName(posttype, data));
}

function getDivisionName(posttype, data) {
    switch (posttype) {
        case 'neighborhood':
            return data.division_name;
        case 'residence':
            return data.division_name;
        case 'quickmovein':
            return data.qmi_detail.division_name;
        case 'mpc':
            return data.division_name;
        case 'promotion': 
            return data.division_name;
        default:
            return '';
    }
}

function persistReferrer(referrer) {
    const now = new Date();
    const expiry = now.setDate(now.getDate() + EXPIRATION_IN_DAYS);
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify({ referrer, expiry }));
}

function fetchReferrer() {
    const {referrer, expiry} = JSON.parse((window.localStorage.getItem(STORAGE_KEY) ??  '{}'));
    if (!referrer || !expiry || expiry <= new Date()) return null;
    return referrer;  
}

export function formatPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace(/^\+1/g, '');
    
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    
    if (match) {
        return `${match[1]}.${match[2]}.${match[3]}`;
    }
    
    return phoneNumber;
}
